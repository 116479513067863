





































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '@/lib/util'
import PageRequest from '@/store/entities/page-request'
import NodeForm from './node-form.vue'
import SortingModel from "@/store/entities/sortingmodel";
import AbpBase from "@/lib/abpbase";
import NodeDto from "@/store/entities/jobcontext/node";


@Component({
    components: {NodeForm}
})
export default class Node extends AbpBase {
    async edit(id: number) {
        this.$store.commit('node/edit', await this.$store.dispatch({
            type: 'node/get',
            data: id
        }));
        this.editModalShow = true;
    }

    pagerequest: PageRequest = new PageRequest();

    createModalShow: boolean = false;
    editModalShow: boolean = false;

    get list() {
        return this.$store.state.node.list;
    };

    async search() {
        this.$store.commit('node/setCurrentPage', 1);
        await this.getPage();
    }

    get loading() {
        return this.$store.state.node.loading;
    }

    create() {
        this.$store.commit('node/edit', new NodeDto());
        this.createModalShow = true;
    }

    pageChange(page: number) {
        this.$store.commit('node/setCurrentPage', page);
        this.getPage();
    }

    pageSizeChange(pageSize: number) {
        this.$store.commit('node/setPageSize', pageSize);
        this.getPage();
    }

    async getPage() {
        this.pagerequest.maxResultCount = this.pageSize;
        this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'node/getAll',
            data: this.pagerequest
        })
    }

    get pageSize() {
        return this.$store.state.node.pageSize;
    }

    get totalCount() {
        return this.$store.state.node.totalCount;
    }

    get currentPage() {
        return this.$store.state.node.currentPage;
    }

    async changeSort(data) {
        if (this.pagerequest.sorting == null) {
            this.pagerequest.sorting = [];
        }
        let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
        if (item == null) {
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pagerequest.sorting.push(sortingModel)
        } else {
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getPage()
    }

    columns = [{
        title: this.L('Name'),
        key: 'name',
        sortable: 'custom'
    }, {
        title: this.L('Actions'),
        key: 'actionCount'
    }, {
        title: this.L('Documents'),
        key: 'documentCount'
    }, {
        title: this.L('Fields'),
        key: 'fieldCount'
    }, {
        title: this.L('Order'),
        key: 'order',
        width: 200,
        sortable: 'custom'
    }, {
        title: this.L('Actions'),
        key: 'Actions',
        width: 150,
        render: (h: any, params: any) => {
            return h('div', [
                h('Button', {
                    props: {
                        type: 'primary',
                        size: 'small'
                    },
                    style: {
                        marginRight: '5px'
                    },
                    on: {
                        click: async () => {
                            await this.edit(params.row.id);
                        }
                    }
                }, this.L('Edit')),
                h('Button', {
                    props: {
                        type: 'error',
                        size: 'small'
                    },
                    on: {
                        click: async () => {
                            this.$Modal.confirm({
                                title: this.L('Tips'),
                                content: this.L('Delete Node Confirm'),
                                okText: this.L('Yes'),
                                cancelText: this.L('No'),
                                onOk: async () => {
                                    await this.$store.dispatch({
                                        type: 'node/delete',
                                        data: params.row
                                    })
                                    await this.getPage();
                                }
                            })
                        }
                    }
                }, this.L('Delete'))
            ])
        }
    }]

    async created() {
        await this.getPage();
    }
}
