





















































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Node from '../../../../store/entities/jobcontext/node'

@Component
export default class DocumentForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({type: Boolean, default: false}) public toEdit: boolean;
    node: Node = new Node();

    documents: any;
    actions: any;
    fields: any;

    data() {
        return {
            documents: [],
            actions: [],
            fields: []
        }
    };

    async created() {
        this.documents = await this.$store.dispatch('document/lookUp');
        this.actions = await this.$store.dispatch('action/lookUp');
        this.fields = await this.$store.dispatch('fieldMetadata/lookUp');
    }

    save() {
        (this.$refs.nodeForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: this.toEdit ? 'node/update' : 'node/create',
                    data: this.node
                });
                (this.$refs.nodeForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.nodeForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            this.node = Util.extend(true, {}, this.$store.state.node.editNode);
        }
    }

    documentRule = {
        name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
    }
}
